import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-fe-input-s',
  templateUrl: './fe-input-s.component.html',
  styleUrls: ['./fe-input-s.component.scss']
})
export class FeInputSComponent implements OnInit {

  @Input() type : string;
  @Input() placeHolder : string;
  @Input() set control(value: FormControl) {
    if (this.formControl !== value) {
      this.formControl = value;
    }
  }
  @Input() maxLength : number;
  formControl: FormControl;

  constructor() { 
  }

  ngOnInit(): void {
  }

}
