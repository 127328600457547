<div class="row no-gutters d-flex align-items-center justify-content-center">
    <ng-container *ngFor="let rating of ratings; let index = i">
        <div class="col-auto m-2" (click)="onSelection(rating)">
            <ng-container *ngIf="rating <= productRating"> 
                <img class="img-fluid" src="/assets/images/app-wide/icons/trophy.svg">
            </ng-container>
            <ng-container *ngIf="rating > productRating"> 
                <img class="img-fluid opacity" src="/assets/images/app-wide/icons/trophy.svg">
            </ng-container>
        </div>
    </ng-container>
</div>