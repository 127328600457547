<ng-container *ngIf="reviewsArray && reviewsArray.length > 0">
    <h1 class="text-center">Lees hier ervaringen!</h1>
    <div class="container-fluid reviews">

        <div class="col-12 review__container d-flex align-items-center">
            <ng-container *ngFor="let review of reviewsArray">
                <div class="col-12 col-lg-4 pl-4 pr-4 d-flex align-items-center flex-wrap item">
                    <lib-landing-cube-review
                        [review]="review">
                    </lib-landing-cube-review>
                   
                </div>
            </ng-container>
            <div class="col-12 col-lg-4 pl-4 pr-4 d-flex align-items-center flex-wrap item">
                <lib-landing-cube-review
                    [review]="null">
                </lib-landing-cube-review>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="reviewsArray.length === 0">
    <div class="container-fluid  reviews-not-available">
        <h1 class="text-center">Lees hier binnekort deelnemers ervaringen!</h1>
    </div>
</ng-container>