import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TermsAndConditionsComponent } from '../../content/terms-and-conditions/terms-and-conditions.component';
import { ShippingConditionsComponent } from '../../content/shipping-conditions/shipping-conditions.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private modalRef : BsModalRef;

  constructor(
    private bsModal : BsModalService
  ) { }

  ngOnInit(): void {

  }

  showTermsAndConditions() : void {
    this.modalRef = this.bsModal.show(TermsAndConditionsComponent);
  }

  showShippingConditions() : void {
    this.modalRef = this.bsModal.show(ShippingConditionsComponent);
  }
}
