import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fe-landing-progress-steps',
  templateUrl: './fe-landing-progress-steps.component.html',
  styleUrls: ['./fe-landing-progress-steps.component.scss']
})
export class FeLandingProgressStepsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
