
<div class="row mt-4 m-0">
    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
      <img
        src="/assets/images/shirts/hoody2.jpeg"
        class="w-100 shadow-1-strong rounded mb-4"
   
      />
  
      <img
        src="/assets/images/shirts/blue-print.png"
        class="w-100 shadow-1-strong rounded mb-4"
        alt="Wintry Mountain Landscape"
      />
    </div>
  
    <div class="col-lg-4 mb-4 mb-lg-0">
     
  
    
      <img
      src="/assets/images/shirts/hoody3.jpeg"
        class="w-100 shadow-1-strong rounded mb-4"
        alt="Mountains in the Clouds"
      />
      <img
        src="/assets/images/brand/logo-try-3.png"
        class="w-100 shadow-1-strong rounded mb-4"
        alt="Boat on Calm Water"
        />
    </div>
  
    <div class="col-lg-4 mb-4 mb-lg-0">
      <img
        src="/assets/images/shirts/hoody1.jpeg"
        class="w-100 shadow-1-strong rounded mb-4"
      
      />
  
      <img
        src="/assets/images/shirts/magenta-print.png"
        class="w-100 shadow-1-strong rounded mb-4"
        alt="Yosemite National Park"
      />
    </div>
</div>