import { Component, OnInit } from '@angular/core';
import { FeLandingSocialsCubesComponent } from '../fe-landing-socials-cubes/fe-landing-socials-cubes.component';

@Component({
  selector: 'app-fe-landing-promo-cubes',
  templateUrl: './fe-landing-promo-cubes.component.html',
  styleUrls: ['./fe-landing-promo-cubes.component.scss', '../fe-landing-socials-cubes/fe-landing-socials-cubes.component.scss']
})
export class FeLandingPromoCubesComponent extends FeLandingSocialsCubesComponent implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
  }

  get isMobile() : boolean {
    return window.innerWidth < 568;
  }

}
