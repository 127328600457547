import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';

@Component({
  selector: 'app-fe-landing-reviews',
  templateUrl: './fe-landing-reviews.component.html',
  styleUrls: ['./fe-landing-reviews.component.scss']
})
export class FeLandingReviewsComponent implements OnInit {

  reviewsArray : Array<any> = [];

  constructor(
    private api : ApiService
  ) { }

  ngOnInit(): void {
    this.api.getReviews().subscribe({
      next : (x : any) => {
        this.mapReviews(x);
      }
    })
  } 

  mapReviews(reviewsArray : any) {
    reviewsArray.forEach(element => {
      element.trophies = this.generateTrophyArray(element.reviewsCustomerRating)
    });

    this.reviewsArray = reviewsArray;
  }

  
  generateTrophyArray(rating : number) : Array<string>{ 
    let trophiesArray = [];
    for(let i = 0; i < rating; i ++){
      trophiesArray.push('');
    }
    return trophiesArray;
  }
}
