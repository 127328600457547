<footer>
    <div class="container-fluid">
        <div class="row no-gutters large-quote">
           <h1>#genietnooitmetmate</h1>
        </div>
        <div class="row no-gutters small-quote">
            <h1>#Achieveyourgoal</h1>
 
            <h1>#enjoylife</h1>
         </div>
        <div class="row no-gutters">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 footer-col" >
                <h4 [routerLink]="['contact']">Contact</h4>
                <div class="footer-container">
                    <div class="footer-item">
                        <span>KVK: 81860196</span>
                        <span>BTW: NL003612469B21</span>
                        <span id="link">for-effect@hotmail.com</span>
                        <span id="link">info@for-effect.com</span>
                        <span id="link" (click)="showTermsAndConditions()">Algemene voorwaarden / <br> Verzending en Retourbeleid</span>
                    </div>
                    <div class="footer-item text-left mt-4">
                        <h4>Betaalmethodes:</h4>
                        <img src="/assets/images/app-wide/payments/ideal.svg">
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 footer-col">
                <h4>Ga For Effect!</h4>
                <div class="footer-container">
                    <div class="footer-item">
                        <span id="link" [routerLink]="['products/weight-loss']">For Effect Coaching Pakket</span>
                        <span id="link" [routerLink]="['order']">Coaching Bestellen</span>
                        <span id="link" [routerLink]="['order/family-box']">FE Family Box Bestellen</span>
                    </div>
                  
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 footer-col">
                <h3></h3>
                <div class="footer-container">
                    <img class="img-fluid logo" src="/assets/images/brand/logo-try-3.png">
                </div>
            </div>
        </div>
    </div>
</footer>
