import { Routes } from '@angular/router';
import { RouteGuard } from './core/guards/route.guard';
import { JWTinterceptor } from './core/interceptors/jwtinterceptor.interceptor';

export const routes: Routes = [
    {
        path: 'home',
        loadChildren: async () => (await import('./contents/content/landing/landing.module')).LandingModule
    },
    {
        path: 'login',
        loadChildren: async () => (await import('./contents/content/login/login.module')).LoginModule
    },
    {
        path: 'account',
        canLoad : [RouteGuard],
        loadChildren: async () => (await import ('./contents/content/account/account.module')).AccountModule
    },
    {
        path: 'shop',
        loadChildren: async () => (await import('./contents/content/shop/shop.module')).ShopModule
    },
    {
        path: 'products',
        loadChildren: async () => (await import('./contents/content/products/products.module')).ProductsModule
    },
    {
        path: 'order',
        loadChildren: async () => (await import('./contents/content/order/order.module')).OrderModule
    },
    {
        path: 'proces',
        loadChildren: async () => (await import('./contents/content/process/process.module')).ProcessModule
    },
    {
        path: 'about',
        loadChildren: async () => (await import('./contents/content/about/about.module')).AboutModule
    },
    {
        path: 'contact',
        loadChildren: async () => (await import('./contents/content/contact/contact.module')).ContactModule
    },
    {
        path: 'intake',
        loadChildren : async () => (await import('./contents/content/group-intake/group-intake.module')).GroupIntakeModule
    },
    {
        path: '**',
        loadChildren: async () => (await import('./contents/content/landing/landing.module')).LandingModule
    }
    

]