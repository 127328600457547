<div class="banner-container">
    <div style=" width: 100%; height: 100%">
        <iframe frameborder="0" height="500px" width="100%"  margin="0" allow='autoplay; encrypted-media'
        frameborder = "0"
          src="https://youtube.com/embed/wgN6OeSK5pg?autoplay=1&amp;mute=1&amp;loop=1&amp;controls=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1">
        </iframe>
      </div>
      <div class="banner-header"> 
        <h3 style="color: var(--blue);">Laat je door ons coachen om je doelen na te streven</h3>

        <button class="form-button" [routerLink]="['/products/muscle-gain']">
          Start nu!
        </button>
       
      </div>
</div>