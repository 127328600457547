//import { item } from '../libs/interfaces/item.interface';

export class ShoppingCartItem{
    productName   : string;
    productPrice?  : number;
    productDescription? : string;
    productId?     : number;
    productAmount? : number;
    productCatagory : string;
    productSize? : string;
    productPriceWithDiscount? : any;

    constructor(i : any, size? : string) {
        this.productName    = i.productName;
        this.productPrice   = i.productPrice;
        this.productDescription  = i.productDescription;
        this.productId      = i.productId;
        this.productAmount  = i.productAmount ?? 1;
        this.productCatagory = i.productCatagory;
        if(size) this.productName = i.productName + ` in: ${size}`
        if(i.productPriceWithDiscount) this.productPrice = i.productPriceWithDiscount;
    }
}

export class ShoppingCartPackage extends ShoppingCartItem{
    productCover? : string;
    bottle : string;
    productGoal? : string;
    productPrice?: number;
    productAmount?: number;
    productCatagory: string;
    productIsCoaching : boolean;
    productExtra? : any;

    constructor(i : any, y? : any) {
        super(i);
        this.productCover = i.productCover;
        this.productAmount = 1;
        this.bottle = i.bottle;
        this.productGoal = i.productGoal;
        this.productPrice = i.productPrice;
        this.productCatagory = i.productCatagory
        this.productIsCoaching = true;

        if(y){
            this.productExtra = {
                title : y.productExtraTitle,
                detail : y.productExtraDescription
            }
        }

        if(i.productExtra){
            this.productExtra = {
                title : i.productExtra.title,
                detail : i.productExtra.detail
            }
        }

        if(i.productPriceWithDiscount) this.productPrice = i.productPriceWithDiscount;

    }
}