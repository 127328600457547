import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _currentUser : Object;

  constructor(
    private http : HttpClient,
    private router : Router,
    private jwt : JwtHelperService
  ){ 
    if(!this._currentUser && this.IsLoggedIn){
      this._currentUser = JSON.parse(localStorage.getItem('user'));
    }
  }

  login(credentials : Object) : Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/api/login/login` , credentials, { observe : 'response'})
  }

  setCredentials(credentials : any){
    this._currentUser = credentials.user;
    localStorage.setItem('token', credentials.jwtBearer);
    localStorage.setItem('user', JSON.stringify(credentials.user))
  }

  updateAddressInfo(address : any, customerId : any){
    const data = {
      address : address,
      customerId : customerId
    }
    return this.http.put(`${environment.apiBaseUrl}/api/customer-data/address`, data , { observe : 'response'})
  }

  updateContactInfo(contactInfo : any, customerId : any){
    const data = {
      contactInfo : contactInfo,
      customerId : customerId
    }
    return this.http.put(`${environment.apiBaseUrl}/api/customer-data/contact-info`, data , { observe : 'response'})
  }
  
  updateCurrentUser(updatedCustomer : any) {
    localStorage.setItem('user', JSON.stringify(updatedCustomer));
  }
  
  sendPasswordRequest(email : any){
    return this.http.get(`${environment.apiBaseUrl}/api/login/password-request`,  { params : { email : email.email }})
  }

  sendNewPassword(form : any, key : string){ 
    const body = {
      form : form,
      key : key
    }

    return this.http.post(`${environment.apiBaseUrl}/api/login/reset`, body)
  }
  getCurrentUser() : any{
    return this._currentUser;
  }

  logOut(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigate(['/home'])
  }

  get IsLoggedIn(){
    const token = localStorage.getItem('token')
    return (!this.jwt.isTokenExpired(token))
  }
}
