import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanLoad {
  
  constructor(
    public auth : AuthenticationService,
    public router : Router) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if(this.auth.IsLoggedIn) return true;
      else {
        this.router.navigate(['/home']);
        return false;
      }
  }  
}
