import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-landing-cube-review',
  templateUrl: './landing-cube-review.component.html',
  styleUrls: ['./landing-cube-review.component.scss'],
})
export class LandingCubeReviewComponent {
  @Input() review : any;
}
