<div class="modal-body">

    <h1>Verzend en Retour beleid For-Effect</h1>
    <span>
        
    </span>
    <header> Artikel 1: Algemeen</header> 
    <p>
        
        <span><b>1.1</b> In deze Koopvoorwaarden wordt verstaan onder: ‘For-Effect’ of ‘For-Effect.com Internet-Shop’: For-Effect; klant: de (potentiële) klant van zaken en/of diensten van For-Effect; ‘bevestiging’: de ontvangstbevestiging, zoals omschreven in artikel 2.2 en 3.1.’Koopvoorwaarden’: deze Koopvoorwaarden.</span>
        <span><b>1.2</b> Deze Koopvoorwaarden zijn van toepassing op alle aanbiedingen, bestellingen en overeenkomsten van For-Effect. Door het tot stand komen van een overeenkomst tussen de klant en For-Effect middels de procedure zoals deze is omschreven in deze Koopvoorwaarden, accepteert de klant uitdrukkelijk deze voorwaarden.</span>
        <span><b>1.3</b> Alle rechten en aanspraken, zoals in deze Koopvoorwaarden, en in eventuele nadere overeenkomsten die ten behoeve van For-Effect worden bedongen, worden evenzeer bedongen ten behoeve van door For-Effect ingeschakelde tussenpersonen en andere derden.</span>
        <span><b>1.4</b> Afwijkingen van deze Koopvoorwaarden zijn slechts geldig indien deze uitdrukkelijk schriftelijk met For-Effect zijn overeengekomen.</span>
        <span><b>1.5</b> Indien (een) bepaling(en) van deze Koopvoorwaarden nietig is/zijn of vernietigd wordt/worden, zullen de overige bepalingen van deze Koopvoorwaarden volledig van kracht blijven. For-Effect en de klant zullen dan in overleg treden teneinde (een) nieuwe bepaling(en) ter vervanging van de nietige c.q. vernietigde bepaling(en) overeen te komen, waarbij zoveel mogelijk het doel en de strekking van de nietige c.q. vernietigde bepaling(en) in acht wordt/worden genomen.</span>
        <span><b>1.6</b> For-Effect heeft te allen tijde het recht deze Koopvoorwaarden en de inhoud van de For-Effect website te wijzigen.</span>
 
    </p>
</div>
