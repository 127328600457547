

<div class="container-fluid socials-cubes-container  d-block col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
    <div class="row no-gutters shirts-container light mt-4">
        <div class="col-12 d-flex justify-content-center">
            <img class="img-fluid"  src="assets/images/back-from-summer/BFH-banner-large.PNG">
        </div>
    </div>
    <div class="row no-gutters shirts-container light mt-4">
        <div class="socials-cubes-cube banner mb-4 mb-lg-1 col-12 col-lg-6 text-center">
            <div class="col-12 text-center">
                <h1>Back From Holiday = terug!</h1>
            </div>
            <div class="cube-x mt-2 mb-2">
                De jaarlijke BFH Challenge is terug! Schrijf je in voor een 8 weken lang traject waarin jij en je vrienden óf familie leden mee kunnen doen.
                Tijdens de BFH challenge krijg je een persoonlijk plan waarmee jij onder begeleiding van onze trainers aan de slag gaat. 
            </div>
            <button class="w-50 order mt-4 mb-4 p-2 m-auto order-summer" [routerLink]="['/order/bfs']"> 
                Ik doe mee!
            </button>
        </div>
        <div class="socials-cubes-cube banner mb-4 mb-lg-1 col-12 col-lg-6">
                <img class="img-fluid summer-banner" src="assets/images/back-from-summer/BFH-afvallen.JPG">
        </div>
        <div class="socials-cubes-cube d-none d-lg-flex banner mb-4 mb-lg-1 col-12 col-lg-6">
                <img class="img-fluid summer-banner" src="assets/images/back-from-summer/BFH-spiermassa.PNG">
        </div>
        <div class="socials-cubes-cube banner mb-4 mb-lg-1 col-12 col-lg-6 text-center">
            <div class="col-12 text-center">
                <h1>Halen jullie het beste resultaat?</h1>
            </div>
            <div class="cube-x mt-2 mb-2">
                Tijdens de BFH Challenge strijden jij en je teamgenoten tegen andere deelnemende groepen! Aan het einde van het traject kijkt For-Effect naar de behaalde resultaten. Hebben jullie het best gescored? Dan winnen jullie een te gekke prijs
                van For-Effect!
            </div>
            <button class="w-50  order mt-4 mb-4 p-2 m-auto order-summer" [routerLink]="['/order/bfs']"> 
                Tuurlijk win ik!
            </button>
        </div>
        <div class="socials-cubes-cube d-flex d-lg-none banner mb-4 mb-lg-1 col-12 col-lg-6">
            <img class="img-fluid summer-banner" src="assets/images/back-from-summer/BFH-spiermassa.PNG">
    </div>
    </div>

    <!-- <ng-container *ngIf="isMobile">
        <div class="row no-gutters shirts-container light mt-4">
            <div class="col-12 text-center">
                <h1>Het Zomer pakket = terug!</h1>
            </div>
            <div class="socials-cubes-cube banner col-6">
                <div class="cube-x">
                    <img class="img-fluid summer-banner" src="assets/images/summer/summer-banner2.jpeg">
                </div>
            </div>
            <div class="socials-cubes-cube banner col-6">
                <img class="img-fluid summer-banner"src="assets/images/summer/summer-banner3.jpeg">
            </div>
        </div>
        <div class="row no-gutters shirts-container light mt-4">
            <div class="col-12 text-center">
                <button class="w-50  order mt-2 mb-4 p-2 m-auto order-summer" [routerLink]="['/order']"> 
                    Bestel nu
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isMobile">
        <div class="row no-gutters shirts-container light mt-4">
            <div class="col-12 text-center">
                <h1>Het Zomer pakket = terug!</h1>
            </div>
            <div class="socials-cubes-cube banner col-6">
                <div class="cube-x">
                    <img class="img-fluid summer-banner" src="assets/images/summer/summer-banner2.jpeg">
                </div>
            </div>
            <div class="socials-cubes-cube banner col-6">
                <img class="img-fluid summer-banner"src="assets/images/summer/summer-banner3.jpeg">
            </div>
        </div>
        <div class="row no-gutters shirts-container light mt-4">
            <div class="col-12 text-center">
                <button class="w-50  order mt-2 mb-4 p-2 m-auto order-summer" [routerLink]="['/order']"> 
                    Bestel nu
                </button>
            </div>
        </div>
    </ng-container> -->
<!-- 
<div class="row no-gutters shirts-container light mt-4">
   
    <div class="d-flex col-12 col-lg-6" [routerLink]="['/order/back-from-summer']">
        <img class="img-fluid" [routerLink]="['/order/back-from-summer']" src="/assets/images/landing/banner.jpeg">
    </div>
    <div class="col-12 col-lg-4 offset-lg-1">
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4 m-auto">
            <h1> Lars is jarig en trakteert!</h1>
            <div class="text-large">
                Tot 12 Oktober pak jij 26% korting op producten, trainings én voedingsschema's. Op coaching pakketten krijg je 26 Euro korting! Wie jarig is, trakteert. Check snel de webshop en bestel jouw favoriete items nú met extra korting!
            </div>
        </div>
        <div class="text-center w-100 mt-4">
            <button class="order mt-2" style="background-color: var(--red);" [routerLink]="['/shop']">Bestellen!</button>
        </div>
    </div>
  
 
</div> -->
    <!-- FE Startup 2024
    <div class="row no-gutters shirts-container dark mt-4">
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4">
            <h1>Nieuw Bij For Effect!</h1>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-4 col-xl-4">  
            <div class="cube">
                <img class="img-fluid" src="assets/images/shirts/blue-print.png">
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div class="cube">
                <img class="img-fluid" src="assets/images/shirts/magenta-print.png">
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div class="cube">
                <div class="header">
                    <h3>Galaxy Blue & Magenta</h3>
                </div>
                <div class="text-large">
                    For Effect lanceert zijn eigen kleding lijn! Met de Galaxy Blue en Galaxy Magenta, printed by order t-shirts, ga jij vol in de gym én de club.
                    Bekijk onze nieuwe t-shirts <span [routerLink]="'/shop'"> <u>hier!</u></span> p.s. Als je er toch al bent, doe 'm alvast in je winkelmandje!

                <br><br>
                    <div class="text-center">
                        <button class="order mt-2"[routerLink]="['/shop']">Bestellen!</button>
                    </div>
                   
                </div>
               
            </div>
        </div>
      
    </div> -->

     <!--
 <div class="row no-gutters promo-container dark mt-4">
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4">
            <h1>Happy New Year!</h1>
            <div class="text-large text-center">
                Check onze meest voordelige deal ooit!                
            </div>
        </div>

        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6">  
            <div class="cube p-4">
                <img class="img-fluid" src="assets/images/new-year/image00001.jpeg">
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
            <div class="cube">
                <div class="header">
                    <h3>Nieuwe ronde nieuwe kansen</h3>
                    <div class="text-center" [routerLink]="['/order']">
                        <button class="w-auto order mt-2 mb-4 p-2">Let's gooo!
                            Coaching Pakket -10%
                        </button>
                    </div>
                    <h3>Check hieronder alle strakke deals!</h3>
                </div>

            </div>
        </div>
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4">
            <h3> Kies jouw Start Up voordeel </h3>
        </div>
        <div class="socials-cubes-cube col-12 d-flex mb-4 flex-wrap">

            <div class="col-sm-12 col-md-6 col-xl-3 col-xxl-3 mb-4 startup text-center"  [routerLink]="['/order/scheme']">
                <h1>Voedingsschema</h1>
                <div class="discount-flag d-flex align-items-center justify-content-center">
                    50% Korting!
                 </div>
                <img class="img-fluid m-auto d-flex" style="max-height: 350px;" src="assets/images/new-year/image000012.jpeg">
                <div class="w-auto text-center list mt-4 p-2">
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- los voedingsschema <br></div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-xl-3 col-xxl-3 mb-4 startup text-center"  [routerLink]="['/order/startup']">
                <h1>StartUp Basic</h1>
                <div class="discount-flag d-flex align-items-center justify-content-center">
                    €29,- Korting!
                 </div>
                <img class="img-fluid m-auto d-flex" style="max-height: 350px;" src="assets/images/new-year/image000014.jpeg">
                <div class="w-auto text-center list mt-4 p-2">
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- los voedingsplan in een map<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- waterfles naar keuze <br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- 1 hoofdstuk uit het boek<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- Tof verpakt<br></div>
                    
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-xl-3 col-xxl-3 mb-4 startup text-center" [routerLink]="['/order/startup']">
                <h1>StartUp Pro</h1>
                <div class="discount-flag d-flex align-items-center justify-content-center">
                    €42,- Korting!<br>
                    Meest gekozen!
                 </div>
                <img class="img-fluid m-auto d-flex" style="max-height: 350px;" src="assets/images/new-year/image000013.jpeg">
                <div class="w-auto text-center list mt-4 p-2">
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- voeding én trainingsplan in een map<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- Waterfles naar keuze<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- ⁠progressie tabel<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- ⁠1 hoofdstuk uit het boek<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- Tof verpakt<br></div>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-xl-3 col-xxl-3 mb-4 startup text-center" [routerLink]="['/order']">
                <h1>Coachingpakket</h1>
                <div class="discount-flag d-flex align-items-center justify-content-center">
                    10% Korting!
                 </div>
                <img class="img-fluid m-auto d-flex" style="max-height: 350px;" src="assets/images/new-year/coaching.jpeg">
                <div class="w-auto text-center list mt-4 p-2">
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- Map met de benodigde informatie<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- Persoonlijk trainingsschema<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- 10 leerzame hoofdstukken<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- persoonlijk Voedingschema met variaties<br></div>
                    <div class="mb-3"><img class="img-fluid" width="25" height="25" src="/assets/images/app-wide/icons/trophy.svg">- Waterfles naar keuze<br></div>
                </div>
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4 pb-4">
            <div class="cube">
                <div class="header">
                    <h3>New Year Mega Voordeel</h3>
                    
                </div>
                <div class="text-large">
                    Op zoek naar een kickstart voor je goede voornemens in het nieuwe jaar? Maak kennis met FE Start up en FE Start up Pro! Of je nu net begint aan je fit journey of al onderweg bent, deze pakketten zijn dé game changers om je doelen waar te maken. FE Start up geeft je een top voedingsplan in een handige map, een coole waterfles naar keuze en een inspirerend hoofdstuk uit ons boek om je op weg te helpen. Maar als je echt next level wilt gaan, ga dan voor FE Start up Pro! Hier krijg je niet alleen een gedetailleerd voedings- én trainingsplan in een handige map, een te gekke waterfles naar keuze en een hoofdstuk uit ons boek, maar ook een super handige progressie tracker om je successen bij te houden. Zet die eerste stap richting je gezonde levensstijl met FE Start up en FE Start up Pro!
                </div>
               
            </div>
        </div>
    </div>
    <div class="row no-gutters promo-container dark mt-4">
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4">
            <h1>Happy New Year!</h1>
            <div class="text-large text-center">
                Check onze meest voordelige deal ooit!                
            </div>
        </div>

        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6">  
            <div class="cube">
                <img class="img-fluid" src="assets/images/rens-promo/rens-promo-bday.jpeg">
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6  col-lg-6 col-xl-6">
            <div class="cube">
                <div class="header">
                    <h3>Nieuwe ronde nieuwe kansen</h3>
                </div>
                <div class="text-large">
                    Op zoek naar een kickstart voor je goede voornemens in het nieuwe jaar? Maak kennis met FE Start up en FE Start up Pro! Of je nu net begint aan je fit journey of al onderweg bent, deze pakketten zijn dé game changers om je doelen waar te maken. FE Start up geeft je een top voedingsplan in een handige map, een coole waterfles naar keuze en een inspirerend hoofdstuk uit ons boek om je op weg te helpen. Maar als je echt next level wilt gaan, ga dan voor FE Start up Pro! Hier krijg je niet alleen een gedetailleerd voedings- én trainingsplan in een handige map, een te gekke waterfles naar keuze en een hoofdstuk uit ons boek, maar ook een super handige progressie tracker om je successen bij te houden. Zet die eerste stap richting je gezonde levensstijl met FE Start up en FE Start up Pro!
                    <span [routerLink]="'/order'"> <u>hier!</u></span> p.s. Als je er toch al bent, doe 'm alvast in je winkelmandje!
                <br><br>
                    <div class="text-center" [routerLink]="['/order']">
                        <button class="order mt-2">Sign me up!</button>
                    </div>
                   
                </div>
               
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6  col-lg-6 col-xl-6">
            <div class="cube">
                <div class="header">
                    <h3>Klaar voor de start?</h3>
                </div>
                <div class="text-large">
                    Ready om jouw goede voornemens te crushen? FE Start up en FE Start up Pro zijn de perfecte maatjes voor jouw fitnessdoelen! FE Start up geeft je een uitgebreid voedingsplan in een handige map, een toffe waterfles naar keuze en een motiverend hoofdstuk uit ons boek. Maar als je écht serious business wilt, is er FE Start up Pro! Dit premium pakket heeft niet alleen een gedetailleerd voedings- en trainingsplan in een handige map, een te gekke waterfles naar keuze en een hoofdstuk uit ons boek, maar ook een must-have progressietabel om je voortgang te tracken. Zet vandaag nog die stap richting jouw nieuwe fit life met FE Start up of FE Start up Pro en maak van jouw goede voornemens een reality!
                <br><br>
                    <div class="text-center" [routerLink]="['/order']">
                        <button class="order mt-2"[routerLink]="['/order']">Letsgoo!</button>
                    </div>
                   
                </div>
               
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="cube">
                <img class="img-fluid" src="assets/images/rens-promo/combi-promo.jpeg">
            </div>
        </div>
    </div>
    <div class="row no-gutters promo-container dark mt-4">
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4">
            <h1>Rens is jarig!</h1>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6">  
            <div class="cube">
                <img class="img-fluid" src="assets/images/rens-promo/rens-promo-bday.jpeg">
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6  col-lg-6 col-xl-6">
            <div class="cube">
                <div class="header">
                    <h3>Hieperdepiep</h3>
                </div>
                <div class="text-large">
                    Rens is vandaag 22 geworden en gooit een feestje op z'n eigen manier! Om deze mijlpaal te vieren, deelt hij wat liefde uit aan z'n klanten. Dus, als je op zoek bent naar wat coaching magic, krijg je nu 22 euro korting op de coaching pakketten van Rens bij For Effect. Hij heeft altijd al een talent gehad om anderen naar succes te leiden, en deze actie is zijn manier om dat te vieren. Dus, waar wacht je nog op? Duik in die persoonlijke groei en pak die korting! 🎉
                    <span [routerLink]="'/order'"> <u>hier!</u></span> p.s. Als je er toch al bent, doe 'm alvast in je winkelmandje!
                <br><br>
                    <div class="text-center" [routerLink]="['/order']">
                        <button class="order mt-2">Dit wil ik!</button>
                    </div>
                   
                </div>
               
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6  col-lg-6 col-xl-6">
            <div class="cube">
                <div class="header">
                    <h3>Hoera!</h3>
                </div>
                <div class="text-large">
                    Guess what? Rens trakteert op een kick-ass combi-deal! Scoor nu niet alleen 22 euro korting op zijn coaching pakketten bij For Effect, maar krijg ook nog eens een gratis shirt van Powerfully erbij! Rens is niet alleen een expert in het begeleiden van anderen naar succes, maar hij gooit ook nog wat extra's in de mix voor zijn verjaardag. Dus, als je klaar bent voor een dubbele dosis motivatie en stijl, pak dan die korting en rock dat Powerfully shirt terwijl je jezelf naar nieuwe hoogten stuwt. Feestje! 🚀🎁
                    <span [routerLink]="'/order'"> <u>hier!</u></span> p.s. Als je er toch al bent, doe 'm alvast in je winkelmandje!
                <br><br>
                    <div class="text-center" [routerLink]="['/order']">
                        <button class="order mt-2"[routerLink]="['/order']">Gaaf!</button>
                    </div>
                   
                </div>
               
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="cube">
                <img class="img-fluid" src="assets/images/rens-promo/combi-promo.jpeg">
            </div>
        </div>
    </div>

   
 
        -->
</div>




    <!--
        <ng-container *ngIf="isMobile">
            <div class="row no-gutters shirts-container light mt-4">
                <div class="socials-cubes-cube banner col-sm-12 col-md-6 col-lg-6 col-xl-6">  
                    <div class="cube-x">
                        <h2>Het FE Zomer pakket = terug!</h2>
                        <img class="img-fluid" src="assets/images/summer/summer-banner.jpeg">
                        <div class="text-large p-4">
                            <h3>3 maanden coaching met korting!</h3>
                        </div>
                    </div>
                </div>
    
                <div class="socials-cubes-cube banner col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="cube-x">
                        <img class="img-fluid" src="assets/images/summer/summer-banner-promo.jpeg"> 
                        <div class="text-center">
                            <button class="order mt-2"[routerLink]="['/order/summer']">Bestel met korting!</button>
                        </div>
                        <div class="text-large p-4">
                            Yes! Na het succes van vorig jaar is het zomerpakket terug. Dat betekend flinke korting!<br><br>
                            Het 3 maanden coaching traject is van 195,- naar 159,- afgeprijsd. Maar er is iets NIEUWS! Dit jaar kan je ook het 3 maanden traject aanschaffen zonder spullen. Dat betekend nog meer korting!<br><br>
                                Bij het zomerpakket ontvangt u ook ons nieuwste trainingsschema. Dit schema is voor 6 maanden! U kunt dus na het zomerpakket gewoon nog doorpakken.<br><br>
                                In 3 maanden je beste shape bereiken vlak voor de zomer. We gaan met z’n alle For Effect!
                            <br><br>
                           
                        </div>
                    </div>
                </div>      
               
               
            </div>
        </ng-container>
        <ng-container *ngIf="!isMobile">
            <div class="row no-gutters shirts-container light mt-4">
                <div class="socials-cubes-cube banner col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="cube-x">
                        <h2>FE Zomer </h2>
                        <div class="text-large p-4">
                            Yes! Na het succes van vorig jaar is het zomerpakket terug. Dat betekend flinke korting!<br><br>
                            Het 3 maanden coaching traject is van 195,- naar 159,- afgeprijsd. Maar er is iets NIEUWS! Dit jaar kan je ook het 3 maanden traject aanschaffen zonder spullen. Dat betekend nog meer korting!<br><br>
                             Bij het zomerpakket ontvangt u ook ons nieuwste trainingsschema. Dit schema is voor 6 maanden! U kunt dus na het zomerpakket gewoon nog doorpakken.<br><br>
                              In 3 maanden je beste shape bereiken vlak voor de zomer. We gaan met z’n alle For Effect!
                            <br><br>
                            <div class="text-center">
                                <button class="order mt-2"[routerLink]="['/order/summer']">Bestel met korting!</button>
                            </div>
                        
                        </div>
                      
                       
                    </div>
                </div>
                <div class="socials-cubes-cube banner col-sm-12 col-md-6 col-lg-6 col-xl-6">  
                    <div class="cube-x">
                        <h2>Het FE Zomer pakket = terug!</h2>
                        <img class="img-fluid" src="assets/images/summer/summer-banner.jpeg">
                    </div>
                </div>
                <div class="socials-cubes-cube banner col-sm-12 col-md-6 col-lg-6 col-xl-6">  
                    <div class="cube-x">
                        <img class="img-fluid" src="assets/images/summer/summer-banner-promo.jpeg"> 
                    </div>
                </div>
            </div>
        </ng-container>

    <div class="row no-gutters shirts-container light mt-4">
       
        <div class="d-flex col-12 col-lg-6" [routerLink]="['/order/back-from-summer']">
            <img class="img-fluid" [routerLink]="['/order/back-from-summer']" src="/assets/images/landing/banner.jpeg">
        </div>
        <div class="col-12 col-lg-4 offset-lg-1">
            <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4 m-auto">
                <h1> Lars is jarig en trakteert!</h1>
                <div class="text-large">
                    Tot 12 Oktober pak jij 26% korting op producten, trainings én voedingsschema's. Op coaching pakketten krijg je 26 Euro korting! Wie jarig is, trakteert. Check snel de webshop en bestel jouw favoriete items nú met extra korting!
                </div>
            </div>
            <div class="text-center w-100 mt-4">
                <button class="order mt-2" style="background-color: var(--red);" [routerLink]="['/shop']">Bestellen!</button>
            </div>
        </div>
      
     
    </div>
    -->