
<div class="container-fluid cubes-container">
    <div class="row no-gutters">
        <div class="cubes-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h1>For Effect Lifestyle</h1>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="cubes-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            
        </div>
    </div>
    <div class="row no-gutters">
        <div class="cubes-cube col-sm-12 col-md-12 col-lg-4 col-xl-4" id="voeding">  
            <div class="cube-container">
                <div class="header">
                    <h3>Voeding - Leer bewust genieten</h3>
                </div>
                <div class="text-large">
                    Gezonde voeding is de belangrijkste factor van een gezonde levenstijl. Bij For Effect proberen wij, 
                    naast het gezonde aspect, ook de gezellige kant van een gezonde levenstijl te accentueren!
                </div>
                <div class="cubes-image">
                    <img class="img-fluid lid" src="/assets/images/app-wide/voeding/FE-balance.svg">
                </div>
            </div>
        </div>
        <div class="cubes-cube col-sm-12 col-md-12 col-lg-4 col-xl-4" id="plan">
            <div class="cube-container">
                <div class="header">
                    <h2>Persoonlijke analyse!</h2>
                </div> 
                <div class="text-large">
                    <p>Bij For Effect gaan wij gezamenlijk voor een pragmatisch plan. 
                        Jij levert ons gegevens aan over jouw huidige fysique en wij bereiden dan een plan van aanpak voor gebaseerd op onze <a>methodiek.</a></p>
                </div>          
                <div class="cubes-image">
                    <img class="img-fluid" src="/assets/images/app-wide/icons/FE-Analyse.svg">
                </div>
            </div>
        </div>
        <div class="cubes-cube col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="cube-container">
                <div class="header">
                    <h3>Beweging - pas dit toe op jouw levensstijl</h3>
                </div>
                <div class="text-large">
                    Een belangrijke factor in een gezonde levenstijl is voldoende beweging. 
                    Dat weten wij allemaal toch? Bewegen is geen hogere wiskunde en eenvoudig toe te voegen in het drukke leven. Ook als je in weekend graag een borreltje doet. Wij helpen jou deze balans te bereiken én behouden!
                </div>
                <div class="cubes-image">
                    <img class="img-fluid" src="/assets/images/app-wide/beweging/FE-active-running.png">
                </div>
            </div>
        </div>
    </div>
</div>
