<div class="modal-body">

    <h1>Koopvoorwaarden For-Effect</h1>
    <span>
        Op het kopen bij For-Effect zijn algemene koopvoorwaarden van toepassing. Op alle teksten, bestellingen en overeenkomsten van For-Effect is het Nederlands recht van toepassing. For-Effect staat ingeschreven bij de Kamer van Koophandel onder nummer 81860196.
    </span>
    <header> Artikel 1: Algemeen</header> 
    <p>
        
        <span><b>1.1</b> In deze Koopvoorwaarden wordt verstaan onder: ‘For-Effect’ of ‘For-Effect.com Internet-Shop’: For-Effect; klant: de (potentiële) klant van zaken en/of diensten van For-Effect; ‘bevestiging’: de ontvangstbevestiging, zoals omschreven in artikel 2.2 en 3.1.’Koopvoorwaarden’: deze Koopvoorwaarden.</span>
        <span><b>1.2</b> Deze Koopvoorwaarden zijn van toepassing op alle aanbiedingen, bestellingen en overeenkomsten van For-Effect. Door het tot stand komen van een overeenkomst tussen de klant en For-Effect middels de procedure zoals deze is omschreven in deze Koopvoorwaarden, accepteert de klant uitdrukkelijk deze voorwaarden.</span>
        <span><b>1.3</b> Alle rechten en aanspraken, zoals in deze Koopvoorwaarden, en in eventuele nadere overeenkomsten die ten behoeve van For-Effect worden bedongen, worden evenzeer bedongen ten behoeve van door For-Effect ingeschakelde tussenpersonen en andere derden.</span>
        <span><b>1.4</b> Afwijkingen van deze Koopvoorwaarden zijn slechts geldig indien deze uitdrukkelijk schriftelijk met For-Effect zijn overeengekomen.</span>
        <span><b>1.5</b> Indien (een) bepaling(en) van deze Koopvoorwaarden nietig is/zijn of vernietigd wordt/worden, zullen de overige bepalingen van deze Koopvoorwaarden volledig van kracht blijven. For-Effect en de klant zullen dan in overleg treden teneinde (een) nieuwe bepaling(en) ter vervanging van de nietige c.q. vernietigde bepaling(en) overeen te komen, waarbij zoveel mogelijk het doel en de strekking van de nietige c.q. vernietigde bepaling(en) in acht wordt/worden genomen.</span>
        <span><b>1.6</b> For-Effect heeft te allen tijde het recht deze Koopvoorwaarden en de inhoud van de For-Effect website te wijzigen.</span>

    </p>
   
    <header>Artikel 2: Aanbiedingen en overeenkomsten</header>
    <p> 

        <span><b>2.1</b> Alle aanbiedingen gedaan op de website van For-Effect zijn vrijblijvend, ook indien deze een termijn van aanvaarding bevatten. For-Effect behoudt zich te allen tijde het recht voor om aanbiedingen in te trekken. Alle aanbiedingen gelden op basis van beschikbaarheid. Prijswijzigingen zijn te allen tijde voorbehouden.</span>
        <span><b>2.2</b> Een overeenkomst tussen For-Effect en de klant komt tot stand nadat de klant:
        Een bestelling heeft geplaatst op de website van For-Effect, middels het volledig en correct invullen van het bestelformulier op de website van For-Effect;
        Het door de klant vervolgens op ‘Afrekenen’ icoon klikken op de website van For-Effect;
        Wanneer er aan de betalingsverplichting is voldaan zal er een orderbevestiging zichtbaar worden. Wanneer er sprake is van een bestelling op het gebied van coaching is er een intakeformulier van toepassing;
        Het hierna verzonden bevestigingsmail met bijkomend intakeformulier dient door de klant juist te worden ingevuld. Eventuele onjuistheden die door de klant bij For-effect zijn ingediend vallen niet onder ons retourbeleid. </span>
        <span><b>2.3</b> De overeenkomst bevat alle tussen de klant en For-Effect gemaakte afspraken en treedt in de plaats van alle eerder gemaakte overeenkomsten, regelingen en/of afspraken tussen de klant en For-Effect.</span>
        <span><b>2.4</b> De administratie van For-Effect geldt, behoudens tegenbewijs, als bewijs van de door de klant aan For-Effect verstrekte opdrachten en gedane betalingen en van door For-Effect verrichte leveringen. For-Effect erkent dat elektronische communicatie als bewijs kan dienen. Door het accepteren van de Koopvoorwaarden erkent de klant dit eveneens.</span>
        <span><b>2.5</b> De ontvangstbevestiging vermeldt in ieder geval de volgende informatie:
        een omschrijving van het product dat door de klant gekocht is en het aantal door de klant gekochte producten;
        de prijs van het product;
        klantgegevens als naam, order ID, woonadres, adres waar het product naar toe gestuurd wordt, factuuradres (indien anders dan woonadres van de klant en/of afleveradres) en e-mailadres en telefoonnummer van de klant;
        het e-mailadres en/of telefoonnummer van de afdeling van For-Effect, waar de klant met vragen omtrent de bestelling terecht kan.</span>

    </p>
    
    <header>Artikel 3: Prijzen/tarieven en betaling</header>
    <p>
        <span><b>3.1</b> Alle prijzen voor de aangeboden zaken zijn in Euro en inclusief omzetbelasting (btw) en andere heffingen die van overheidswege worden opgelegd en exclusief handling- en verzendkosten, tenzij anders vermeld of schriftelijk overeengekomen.</span>
        <span><b>3.2</b> Alle facturen zullen door de klant worden betaald, zonder korting of compensatie binnen veertien (14) werkdagen na factuurdatum, tenzij schriftelijk anders overeengekomen.</span>
        <span><b>3.3</b> In het geval dat de betalingstermijn wordt overschreden, is For-Effect gerechtigd vanaf de vervaldatum van de factuur een rente van 1% per maand aan de klant in rekening te brengen, waarbij een gedeelte van een maand voor een gehele maand wordt gerekend. For-Effect zal bij overschrijding van de betalingstermijn een herinnering sturen.</span>
        <span><b>3.4</b> Indien de klant ook na het versturen van de herinnering niet, niet volledig of niet voor de in de herinnering gestelde uiterste datum van betaling heeft betaald, heeft For-Effect het recht om haar buitengerechtelijke (incasso-) kosten aan de klant in rekening te brengen. De klant is tevens gehouden tot betaling van daadwerkelijk gemaakte gerechtelijke kosten, voor zover een eventuele proceskostenveroordeling op een lager bedrag uitkomt.</span>
        <span><b>3.5</b> Bij niet nakoming door de klant van hetgeen omtrent betaling is overeengekomen, is For-Effect gerechtigd de overeenkomst met onmiddellijke ingang buitengerechtelijk te ontbinden of haar verplichtingen op te schorten, alsmede gerechtigd de klant zonder opgave van redenen de toegang tot het systeem van For-Effect te weigeren.</span>
    </p>

    <header>Artikel 4: Levering/leveringstermijnen</header>
    <p>
        <span><b>4.1</b> Leveringen vinden uitsluitend plaats binnen Nederland, tenzij op de website van For-Effect anders is aangegeven.</span>
        <span><b>4.2</b> De levertijd bedraagt normaal gesproken maximaal vijf (5) werkdagen, tenzij op de website van For-Effect anders is aangegeven. De levertijden gelden als indicatie en niet als fatale termijn.</span>
        <span><b>4.3</b> De levertermijn van het product bedraagt maximaal dertig (30) werkdagen of zoveel korter dan is bepaald in de overeenkomst tussen de klant en For-Effect.</span>
        <span><b>4.4</b>>Wanneer de overeengekomen levertermijn, om welke reden dan ook, door For-Effect overschreden wordt, zal For-Effect de klant hiervan onmiddellijk schriftelijk (via brief of e-mail) in kennis stellen. In dat geval heeft de klant de bevoegdheid om de overeenkomst met For-Effect te ontbinden, door dit schriftelijk (via brief of e-mail) aan For-Effect te melden.</span>
        <span><b>4.5</b> Eventueel in het kader van voorgaand artikel reeds door de klant gedane betalingen worden zo spoedig mogelijk, maar in ieder geval binnen veertien (14) werkdagen nadat For-Effect het in artikel 4.4 genoemde verzoek tot ontbinding van de overeenkomst heeft ontvangen, op de bank- of girorekening van de klant teruggestort.</span>
        <span><b>4.6</b> De door For-Effect opgegeven levertijden zullen nimmer als fatale termijn te beschouwen zijn, tenzij uitdrukkelijk anders is overeengekomen.</span>
        <span><b>4.7</b> Levering geschiedt op de plaats en het tijdstip dat de zaken gereed zijn voor verzending aan de klant.</span>
        <span><b>4.8</b>For-Effect behoudt zich het recht voor om tot deelleveringen aan de klant over te gaan, zodat een bestelling in twee of meerdere zendingen wordt verzonden.</span>

      
            
    </p>

    <header>Artikel 5: Overmacht en/of bijzondere omstandigheden</header>
    <p>
        
        <span><b>5.1</b> For-Effect is niet gehouden tot het nakomen van enige verplichting jegens de klant indien zij daartoe verhinderd is als gevolg van een omstandigheid, die niet is te wijten aan haar schuld, noch krachtens wet, rechtshandeling of in het verkeer geldende opvattingen voor haar rekening komt.</span>
        <span><b>5.2</b> Omstandigheden in de zin van artikel 5.1 zijn ondermeer een bedrijfsstoring, een storing in de energie- of materiaalaanvoer, transportvertraging, een staking en het niet of niet meer tijdig leveren door leveranciers.</span>

    </p>

    <header>Artikel 6: Eigendomsvoorbehoud</header>
    <p>

        <span><b>6.1</b> Alle aan de klant geleverde zaken blijven eigendom van For-Effect, totdat alle bedragen die de klant verschuldigd is voor de krachtens de overeenkomst geleverde zaken volledig aan For-Effect zijn voldaan.</span>

    </p>
    
    <header>Artikel 7: Risico</header>
    <p>

        <span><b>7.1</b> Het risico tijdens het transport van het door de klant bestelde product is voor For-Effect. Op het moment van aflevering van het product, of het moment dat redelijkerwijze als aflevering beschouwd kan worden, gaat het risico van het product op de klant over, behoudens de aansprakelijkheden die wettelijk niet door For-Effect kunnen worden uitgesloten.</span>

    </p>

    <header>Artikel 8: Intellectuele en industriële eigendomsrechten</header>
    <p>

        <span><b>8.1</b> De klant dient alle intellectuele en industriële eigendomsrechten die rusten op de door For-Effect geleverde zaken geheel en onvoorwaardelijk te respecteren.</span>
        <span><b>8.2</b> For-Effect garandeert niet dat de aan de klant geleverde zaken geen inbreuk maken op enig intellectueel en/of industrieel eigendomsrecht van derden en aanvaardt geen enkele aansprakelijkheid in geval van enige aanspraak van derden gebaseerd op de stelling dat met een door For-Effect geleverde zaak inbreuk wordt gemaakt op enig recht van een derde.</span>
        
    </p>

    <header>Artikel 9: Bestellingen/communicatie</header>
    <p>

        <span><b>9.1</b> For-Effect is op geen enkele manier aansprakelijk voor misverstanden, beschadigingen, vertragingen of niet duidelijk overkomen van bestellingen en mededelingen ten gevolge van het gebruik van internet of enig ander communicatiemiddel in het verkeer tussen de klant en For-Effect, dan wel tussen For-Effect en derden, voor zover betrekking hebbend op de relatie tussen de klant en For-Effect.</span>

    </p>
    <header> Artikel 10: Afkoelingsperiode</header>
    <p>
       
        <span><b>10.1</b> For-Effect behoudt het recht om geretourneerde producten te weigeren of om slechts een gedeelte van het reeds betaalde bedrag te crediteren, wanneer het vermoeden bestaat dat het product reeds is geopend, gebruikt of door de schuld van de klant (anders dan die van For-Effect of de leverancier van het product) is beschadigd.</span>
        <span><b>10.2</b> Indien een product wordt geretourneerd dat naar het oordeel van For-Effect schade heeft opgelopen die aan een handeling of nalatigheid van de klant te wijten is of anderszins voor risico van de klant komt, zal For-Effect de klant hiervan schriftelijk (via fax, brief of e-mail) in kennis stellen. For-Effect heeft het recht om de waardevermindering van het product als gevolg van deze schade van het aan de klant terug te betalen bedrag in te houden.</span>
        <span><b>10.3</b> Wanneer er door For-Effect akkoord wordt gegeven op aangekondigde retourzending zal For-Effect binnen 14 werkdagen het (eventueel aangepaste) retourbedrag aan de klant terugbetalen.</span>

    </p>

    <header>Artikel 11: Garanties</header>
    <p>

        <span><b>11.1</b> Op de door For-Effect geleverde producten bestaat een garantie. Dit is de garantie zoals die door de fabrikant op haar producten wordt vastgesteld. Deze garantie laat de rechten van de klant die hiertoe voortvloeien uit de wet, onverlet.</span>

    </p>

    <header>Artikel 12: Klachten</header>
    <p>
            
        <span><b>12.1</b> Alle klachten in verband met de levering, kwaliteit, hoedanigheid van het product of iedere andere klacht, zullen door For-Effect serieus in behandeling worden genomen.</span>
        <span><b>12.2</b> De klant dient een klacht kenbaar te maken bij de afdeling Support van For-Effect (de gegevens van deze afdeling staan elders vermeld in deze Koopvoorwaarden).</span>
        <span><b>12.3</b> For-Effect zal binnen tien (10) werkdagen de klacht proberen op te lossen. For-Effect zal de klant hierover schriftelijk (via fax, brief of e-mail) berichten.</span>

    </p>
    <header>Artikel 13: Persoonsgegevens</header>
    <p>

        <span>13.1 Door de klant ingevoerde persoonlijke gegevens zullen worden opgenomen in een bestand. Deze gegevens zullen worden gebruikt voor de uitvoering van de bestelling van de klant. Ook zullen de gegevens van eventueel ingevuld intakeformulier worden gebruikt voor het tot stand brengen van het aangeschafte programma. </span>
        <span>13.2 Tenzij de klant heeft aangegeven dat de klant hier geen prijs op stelt, zullen de gegevens van de klant worden opgenomen in een centraal bestand van For-Effect. Deze gegevens zullen worden gebruikt om de klant volledig up-to-date te houden wat betreft de zaken en diensten van For-Effect. De verwerking van de gegevens van de klant zal geschieden in overeenstemming met de toepasselijke wet- en regelgeving.</span>
        <span> De klant kan desgewenst inzage verkrijgen in de gegevens die door For-Effect over de persoon van de klant worden opgenomen in het bestand van For-Effect. De klant is gerechtigd wijziging van de gegevens te verlangen, indien deze gegevens niet correct zijn.</span>

    </p>
    <header>Artikel 14: Afdeling Support van For-Effect</header>
    <p>
       
        <span>Alle correspondentie op basis van deze Koopvoorwaarden vindt plaats met:</span>
        <span>For-Effect</span>
        <span>info@For-Effect.com</span>
        <span>Ook kan er op www.for-effect.com een contactformulier worden ingevuld. For-Effect zal hier binnen 10 werkdagen op antwoorden. </span>
    </p>

    <header>Artikel 15: Diversen</header>
    <p>

    
        <span> 15.1 Indien de klant aan For-Effect schriftelijk opgave doet van een adres, is For-Effect gerechtigd alle bestellingen te verzenden aan het desbetreffende adres, totdat de klant For-Effect een nieuw adres heeft doorgegeven.</span>
        <span>15.2 Indien door For-Effect gedurende korte of langere tijd al dan niet stilzwijgend afwijkingen van deze Koopvoorwaarden zijn toegestaan, laat dat onverlet haar recht alsnog directe en strikte naleving van de Koopvoorwaarden te eisen. De klant kan nimmer enig recht doen laten gelden op grond van het feit dat For-Effect deze Koopvoorwaarden soepel toepast.</span>
        <span>15.3 For-Effect is bevoegd bij de uitvoering van de bestelling(en) van de klant gebruik te maken van derden.</span>

    </p>
    
    <header>Artikel 16: Toepasselijke recht en geschillenregeling</header>
    <p>

        <span>16.1 Op alle aanbiedingen, bestellingen en overeenkomsten van For-Effect is het Nederlands recht van toepassing.</span>
        <span>16.2 Geschillen tussen For-Effect en de klant zullen worden voorgelegd aan de bevoegde Nederlandse Rechter te Amsterdam.</span>

    </p>
    
    
    
</div>
