import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { UiOrganismsModule } from './ui/ui-organisms/ui-organisms.module';
import { UiMoleculesModule } from './ui/ui-molecules/ui-molecules.module';
import { UiAtomsModule } from './ui/ui-atoms/ui-atoms.module';
import { NavbarComponent } from './contents/structure/navbar/navbar.component';
import { FooterComponent } from './contents/structure/footer/footer.component';
import { RouterModule } from '@angular/router';
import { routes } from './routing';
import { TemplatesModule } from './ui/templates/templates.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JWTinterceptor } from './core/interceptors/jwtinterceptor.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { TermsAndConditionsComponent } from './contents/content/terms-and-conditions/terms-and-conditions.component';
import { ShippingConditionsComponent } from './contents/content/shipping-conditions/shipping-conditions.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    TermsAndConditionsComponent,
    ShippingConditionsComponent,
  ],
  imports: [
    BrowserModule, 
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',scrollPositionRestoration: 'enabled'}),
    UiOrganismsModule,
    UiMoleculesModule,
    UiAtomsModule,
    TemplatesModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    JwtModule.forRoot({
			config: {
				tokenGetter,
			  allowedDomains: ["localhost:4200", "http://localhost:4200", "https://localhost:4200", "http://localhost:8080", "localhost:8080"]
			},
		}),
  ],
  exports:[
    FooterComponent
  ],
  bootstrap: [AppComponent],
  providers : [
    { provide: HTTP_INTERCEPTORS, useClass: JWTinterceptor, multi: true },
  ]
})
export class AppModule { }
export function tokenGetter() {
	return localStorage.getItem('Token');
}
