import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShoppingCartService } from 'src/app/core/products/shopping-cart.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  showNav : boolean;
  showDropdown : boolean;

  constructor(private router : Router,
    private route : ActivatedRoute,
    public shoppingCart : ShoppingCartService) {
    this.router.events.subscribe((val) => {
      this.closeMobileMenu();
    });

  
   }

  ngOnInit(): void {
  }

  openMobileMenu(){
    let element = document.getElementById('mobile-menu');
    element.classList.add('show')
  }

  closeMobileMenu(){
    let element = document.getElementById('mobile-menu');
    element.classList.remove('show')
  }

  showDropdownMenu(){
    if(this.showDropdown) this.showDropdown = false;
    else this.showDropdown = true
  }
}
