import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl = `${environment.apiBaseUrl}/api/mail/contact`;

  constructor(
    private http: HttpClient)
     { }

  async sendContactForm(formData : any){
    let response = await this.http.post<any>(this.apiUrl, formData ).toPromise();
    return response;
  } 

  sendCustomerForm(formData : any) :Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/api/customer/fysique-form`, formData);
  }

  async sendCustomerLimForm(formData : any) {
    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/customer/fysique-form-lim`, formData ).toPromise();
    return response;
  }
  
  async createNewCustomer(formData : any){
    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/customer/new-customer`, formData).toPromise();
    return response;
  }

  async createNewCustomerAccount(formData : any){
    let response = await this.http.post<any> (`${environment.apiBaseUrl}/api/customer/create-new-customer`, formData).toPromise();
    return response
  }

  async createIntent(products : any, id : number, selectedDiscountRule? : any){
    const query = selectedDiscountRule 
      ? { products : products, id : id, selectedDiscountRule : selectedDiscountRule}
      : { products : products, id : id}

    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/webhooks-payments/get-intent`, query).toPromise()
    return response;
  } 

  async createOrder(customer : any, prod : any){
    let order = { 
      client : customer.user ?? customer,
      product : prod
    }
    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/orders/order`, order).toPromise();
    return response;
  }

  async createOrderWithLoggedInCustomer(customer : any, prod : any){
    let order = {
      client : customer,
      product : prod
    }
    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/orders/order`, order).toPromise();
    return response;
  }

  async getOrderStatus(orderId : any){
    const orderStatus = await this.http.get<any>(`${environment.apiBaseUrl}/api/orders/order-status`, { params : {orderId : orderId}}).toPromise();
    return orderStatus;
  }

  async validateUserKey(key : string, type : string){
    const k = {
      key : key,
      type : type
    }
    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/customer/validate`, k).toPromise();
    return response;
  }

  async validateGroupUserKey(key : string){
    const k = {
      key : key
    }
    let response = await this.http.post<any>(`${environment.apiBaseUrl}/api/customer/validate-group-key`, k).toPromise();
    return response;
  }

  async getCustomerDataById(customerId : any){
    const response = await this.http.get<any>(`${environment.apiBaseUrl}/api/customer-data/customer`, { params: {id : customerId}}).toPromise()
    return response;
  }

  async createNewGroupCustomer(customer : any, groupId : any){
    const data = { 
      groupId : groupId,
      name : customer.customerName,
      email : customer.customerEmail
    }
    const response = await this.http.post<any>(`${environment.apiBaseUrl}/api/group/new-member`, data ).toPromise();
    return response;
  }

  getCustomerSubscriptionData(customerId : any) : Observable<any>{ 
    return this.http.get<any>(`${environment.apiBaseUrl}/api/mollie-subscription/get-customer-subscription`,  { params: {id : customerId}});
  }

  createMollieCustomer(customer : any): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/mollie-customer/create-mollie-subscription-customer`, {customer})
  }

  createMollieCustomerMandate(mandate : any, customer : any) : Observable<any>{
    return this.http.post<any>(`${environment.apiBaseUrl}/api/mollie-mandate/create-customer-mandate`, {mandate, customer})
  }

  createMollieSubscription(subscriptionData : any, customer : any) : Observable<any>{
    return this.http.post<any>(`${environment.apiBaseUrl}/api/mollie-subscription/create-customer-subscription`, {subscriptionData, customer})
  }

  getReviewProductByToken(token : any) : Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/api/reviews/open-review-by-token`, {params : {reviewToken : token}});
  }

  createNewReviewByToken(token : any, review : any) : Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/api/reviews/new-review-by-token`, {token, review});
  }

  getReviews() : Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/api/reviews/reviews`);
  }

  getReviewsPaginated(paginationParams : any) : Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/api/reviews/reviews-paginated`,{ params : paginationParams});
  }

  verifyDiscountCode(discountCode : string) : Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/api/webhooks-payments/get-discount-code`, {params : {code : discountCode}, observe : 'response'})
  }
}
