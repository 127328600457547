import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeButtonComponent } from './fe-button/fe-button.component';
import { FeInputSComponent } from './fe-input-s/fe-input-s.component';
import { FeLabelComponent } from './fe-label/fe-label.component';
import { FeInputMComponent } from './fe-input-m/fe-input-m.component';
import { FeInputLComponent } from './fe-input-l/fe-input-l.component';

@NgModule({
  declarations: [FeButtonComponent, FeInputSComponent, FeLabelComponent, FeInputMComponent, FeInputLComponent],
  imports: [
    CommonModule
  ],
  exports: [
    FeButtonComponent,
    FeInputSComponent,
    FeLabelComponent,
    FeInputMComponent,
    FeInputLComponent
  ]
})
export class UiAtomsModule { }
