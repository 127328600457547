<nav class="navbar navbar-dark navbar-expand-xl navbar-default m-0">
    <div class="navbar-details navbar-wrapper"  id="navbar-sticky" >
        <div class="socials">
            <h4 [routerLink]="['/home']">#enjoylife</h4>

            <div class="social-items">
                <div class="social-item" style="margin-right: 30px" [routerLink]="['/order/basket']">
                    <a [routerLink]="['/order/basket']" target="_self">
                        <img class="light"  src="/assets/images/app-wide/icons/FE-Shopping.svg">
                        <div class="shoppingcart-amount">{{shoppingCart?.ShoppingCartLength}} </div>
                    </a>       
                </div>
                <div class="social-item" style="margin-right: 15 px" [routerLink]="['/login']">
                    <a [routerLink]="['/login']" target="_self">
                        <img class="dark"  src="/assets/icons/login.png">
                    </a>       
                </div>                          
                <div class="social-item">
                    <a href="https://www.facebook.com/ForEffectOfficial" target="blank">
                        <img  src="/assets/icons/socials/fb-icon.png">
                    </a>                
                </div>
                <div class="social-item" style="margin-right: 40px;">
                    <a href="https://www.instagram.com/foreffect_/" target="blank">
                        <img src="/assets/icons/socials/ig-icon.png">
                    </a>
                </div>
            </div>
           
        </div>
    </div> 
    <div  class="navbar-items-container">
        <div class="navbar-items">
            <img [routerLink]="['/home']" class="logo" src="./assets/images/brand/logo-try-3.png">
            <div class="navbar-item" [routerLink]="['home']">
                Home
            </div>
            <div class="navbar-item" [routerLink]="['shop']">
                Shop
            </div>
            <div class="navbar-item" [routerLink]="['order/bfs']" >
                BFH Challenge
            </div>
            <div class="navbar-item" (click)="showDropdownMenu()">
                Doelen
                <div *ngIf="showDropdown == true" class="drop-down">
                    <div class="drop-down-item" [routerLink]="['products/weight-loss']" >
                        Afvallen
                    </div>
                    <div class="drop-down-item" [routerLink]="['products/muscle-gain']" >
                        Spieropbouw
                    </div>
                </div>
            </div>
            <div class="navbar-item" [routerLink]="['order']" >
                Coaching
            </div>
            <div class="navbar-item" [routerLink]="['about']" >
                For Effect
            </div>
            <div class="navbar-item" [routerLink]="['contact']">
                Contact
            </div>
           
        </div>
    </div>
    <div class="navbar-mobile">
        <div class="navbar-toggler-icon" (click)="openMobileMenu()"></div>
    </div>

    <div id="mobile-menu">
        <a class="closebtn" (click)="closeMobileMenu()"><img src="/assets/icons/close.svg"> </a>
            <div class="navbar-mobile-items-wrapper">
                <div class="navbar-item" [routerLink]="['home']"><h3> Home </h3></div>
                <div class="navbar-item" [routerLink]="['shop']"><h3> Shop </h3></div>
                <div class="navbar-item" [routerLink]="['order/bfs']" >
                    <h3>BFH Challenge</h3>
                 </div>
                <div class="navbar-item" [routerLink]="['products/weight-loss']"><h3> Afvallen </h3></div>
                <div class="navbar-item" [routerLink]="['order']"><h3> Coaching </h3></div>
                <div class="navbar-item" [routerLink]="['products/muscle-gain']"><h3> Spieropbouw </h3></div>
                <div class="navbar-item" [routerLink]="['about']"><h3> For Effect </h3></div>
                <div class="navbar-item" [routerLink]="['contact']"><h3> Contact</h3></div>

            </div>
            <div class="mobile-logo">
                <img class="img-fluid" src="/assets/images/brand/logo-try-3.png">
            </div>
    </div>
</nav>