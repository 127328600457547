<ng-container *ngIf="review">
    <div class="col-12 p-0 mb-4 review-item">
        <div class="review-container d-block">
            <div class="review-icon d-flex align-items-center justify-content-center">
                <div class="review-icon-halo d-flex align-items-center flex-center">
                    <img class="icon m-auto" src="/assets/images/app-wide/icons/trophy.svg">
                </div>
            </div>
            <div class="review-stars">
                <div class="customer-rating d-flex align-items-center justify-content-center">
                    {{review.reviewsCustomerRating}}/10  <img class="ml-2" src="/assets/images/app-wide/icons/trophy.svg">
                </div>
            </div>
            <div class="review-body d-block p-4">
                <div class="review-text w-100 pt-4 mb-auto">
                        {{review.reviewsCustomerExperience}}
                </div>
                <div class="mt-auto review-body--product-data">
                    <div class="review-title col-12 mt-auto pl-0">
                        {{review.reviewsCustomerProduct}}
                    </div>
                    <span class="review-customer mt-auto">
                        {{review.reviewsCustomerName}}
                    </span>
                    <div class="review-date ml-2 pl-4 pr-4 mb-2 mt-auto">
                        {{review.reviewsPurchaseDate}}
                    </div>
                </div>
            </div>  
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!review">
    <div class="col-12 p-0 mb-4 review-item">
        <div class="review-container d-block" >
            <div class="review-icon d-flex align-items-center justify-content-center border-0">
             
            </div>
            <div class="review-stars border-0">
            </div>
            <div class="review-body d-block p-4" [routerLink]="['/shop/reviews']">
                <div class="review-text w-100 pt-4 mb-auto d-flex align-items-center">
                <h1>Lees meer ervaringen</h1>
                </div>
                <div class="mt-auto review-body--product-data">
                    <div class="review-title col-12 mt-auto pl-0 pr-0">
                    </div>
                    <span class="review-customer mt-auto">

                    </span>
                    <div class="review-date ml-2 pl-4 mb-2 mt-auto">

                    </div>
                </div>
            </div>  
        </div>
    </div>
</ng-container>
