<div class="container-fluid progress-steps-container">
    <div class="row no-gutters">
        <div class="progress-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h1>Het For Effect Proces!</h1>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="progress-steps col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="text-large">
                Het For Effect proces is een traject dat jij op eigen tempo en intensiteit kan samenstellen. Binnen 5 stappen helpen wij jou op weg naar een gebalanceerd en gezond leven!
            </div>
            <br> 
            <div class="progress-step ">
                <h4>1. Maak kennis met For Effect.</h4>
            </div>
            <div class="progress-step ">
                <h4>2. Bestel het For Effect programma.</h4>
            </div>
            <div class="progress-step ">
                <h4>3. Vul digitaal jouw data in.</h4>
            </div>
            <div class="progress-step ">
                <h4>4. Ontvang de For Effect Box.</h4>
            </div>
            <div class="progress-step ">
                <h4>5. Start je nieuwe gebalanceerde leven.</h4>
            </div>
            <app-fe-button
                [routerLink]="['/order']"
                [textValue]="'Meld je aan!'">
            </app-fe-button>
        </div>
        
    </div>
</div>
 