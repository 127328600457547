import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-fe-input-l',
  templateUrl: './fe-input-l.component.html',
  styleUrls: ['./fe-input-l.component.scss']
})
export class FeInputLComponent implements OnInit {
  @Input() type : string;
  @Input() placeHolder : string;
  @Input() set control(value: FormControl) {
    if (this.formControl !== value) {
      this.formControl = value;
    }
  }
  @Input() maxLength : number;
  formControl: FormControl;

  constructor() { }

  ngOnInit(): void {
  }

}
