import { Injectable } from '@angular/core';
import { ShoppingCartItem, ShoppingCartPackage } from '../interface/shoppingCartItem';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  sessionId   : string; // gebruikers sessie 
  cartAmount  : number; // aantal producten in het winkelwagentje
  itemCount   : number;
  isNewItem   : boolean;
  newContent  : Array<any> = [];
  cartContent : Array<any> = [];

  constructor() { 
    this.initiateCart();
  }

  initiateCart(){
    const cartContent = JSON.parse(localStorage.getItem('cartContent'));
    if(!cartContent || cartContent?.length == 0){
      this.newContent = []
      this.cartContent = [];
      localStorage.setItem('cartContent', JSON.stringify(this.cartContent))
    }else{
      this.cartContent = cartContent;
    }
  }

  addNewShoppingCartItem(item : ShoppingCartItem | ShoppingCartPackage){
    let newItem;

    if(item instanceof ShoppingCartItem) newItem = new ShoppingCartItem(item);
    if(item instanceof ShoppingCartPackage) newItem = new ShoppingCartPackage(item);

    if(this.hasItems(newItem)){
      for(let i = 0; i < this.cartContent.length; i++){
        if(newItem.productId == this.cartContent[i]?.productId && (newItem.productCatagory !== 'Pakket')  && (item.productName == this.cartContent[i]?.productName)){
          let amount = this.cartContent[i].productAmount;
          amount += 1;
          this.cartContent[i].productAmount = amount;
          this.isNewItem = false;
          break;
        }
        else{
          this.isNewItem = true;
        }
      }
    }
    if(this.isNewItem){
      this.cartContent.push(newItem);
    }
    this.storeCartContent();
  }

  addToShoppingCart(item : ShoppingCartItem | ShoppingCartPackage){
  
    if(this.hasItems(item)){
      for(let i = 0; i < this.cartContent.length; i++){        
        if(item.productId == this.cartContent[i]?.productId && (item.productCatagory !== 'Pakket') && (item.productName == this.cartContent[i]?.productName)){
          let amount = this.cartContent[i].productAmount;
          amount += 1;
          this.cartContent[i].productAmount = amount;
          this.isNewItem = false;
          break;
        }
        else{
          this.isNewItem = true;
        }
      }
    }
    if(this.isNewItem){
      this.cartContent.push(item);
    }
    this.storeCartContent();
  }

  deleteShoppingCartItem(item){
    const oldItem = new ShoppingCartItem(item);
    this.getStoredCartContent();
    for(let i = 0; i < this.cartContent.length; i++){
      if(oldItem.productId === this.cartContent[i].productId){
        let amount = this.cartContent[i].productAmount;
        amount --;
        this.cartContent[i].productAmount = amount;
        if(amount == 0){
           this.cartContent.splice(i, 1);
        }
      }
    }
    this.storeCartContent();
  }

  deleteShoppingCartContent(){
    localStorage.removeItem('cartContent');
    localStorage.removeItem('cartContentFinal');
    localStorage.removeItem('cCred');
    this.initiateCart();
    return this.cartContent;
  }

  hasItems(newItem):boolean{
    if(!this.cartContent){
      this.initiateCart();
    }
    if(this.cartContent.length < 1){
     
      this.cartContent.push(newItem);
      return false;
    }
    else{
      return true;
    }
  }

  checkIfProductHasBeenAdded(selectedProduct : any){
    const shoppingCartContent = JSON.parse(localStorage.getItem('cartContent'));
    const item = shoppingCartContent.find((x : any) => 
      x.productId == selectedProduct.productId &&
      x.productName == selectedProduct.productName);

    if(item) return item.productAmount;
    else return 0;
  }

  storeCartContent(){
    localStorage.setItem('cartContent', JSON.stringify(this.cartContent));
  }

  getStoredCartContent(){
    this.cartContent = JSON.parse(localStorage.getItem('cartContent'));
    return this.cartContent;
  }  

  saveCart(items){
    localStorage.setItem('cartContentFinal', JSON.stringify(items));
    const itemImported = localStorage.getItem('cartContentFinal');

  }

  get ShoppingCartLength() : number{
    let amount = 0;
    this.cartContent.forEach(element => {
      if(element.productCatagory !== 'Package'){
        amount += element.productAmount;
      }else if(element.productCatagory === 'Package'){
        amount += 1;
      }
    });
    return amount ?? 0;
  }

  isGroupInBasket(){
    let cartContent = JSON.parse(localStorage.getItem('cartContent'));
    const present = cartContent.find((x : any) => x.productName.includes('Family Box'));
    return present;
  }
}
 
