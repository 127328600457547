import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fe-landing-socials-cubes',
  templateUrl: './fe-landing-socials-cubes.component.html',
  styleUrls: ['./fe-landing-socials-cubes.component.scss']
})
export class FeLandingSocialsCubesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
