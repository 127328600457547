import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeEmailFormComponent } from './fe-email-form/fe-email-form.component';
import { UiAtomsModule } from '../ui-atoms/ui-atoms.module';
import { ProductRatingComponent } from './product-rating/product-rating.component';



@NgModule({
  declarations: [FeEmailFormComponent, ProductRatingComponent],
  imports: [
    CommonModule,
    UiAtomsModule,
  ],
  exports: [
    FeEmailFormComponent,
    ProductRatingComponent
  ]
})
export class UiMoleculesModule { }
