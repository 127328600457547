import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fe-button',
  templateUrl: './fe-button.component.html',
  styleUrls: ['./fe-button.component.scss']
})
export class FeButtonComponent implements OnInit {

  @Input() textValue : string;

  constructor() { }

  ngOnInit(): void {
  }

}
