<div class="container-fluid motive">
    <div class="row no-gutters">
        <div class="test">
            <div class="col-sm-12  col-md-12 col-lg-6 col-xl-6 hover-tag" [routerLink]="['/products/weight-loss']">
               <h1>Afvallen</h1> 
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 hover-tag" [routerLink]="['/products/muscle-gain']">
               <h1>Spieropbouw</h1> 
            </div>
        </div>           
</div> 