import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeLandingBannerComponent } from './fe-landing-banner/fe-landing-banner.component';
import { UiAtomsModule } from '../ui-atoms/ui-atoms.module';
import { UiMoleculesModule } from '../ui-molecules/ui-molecules.module';
import { UiOrganismsModule } from '../ui-organisms/ui-organisms.module';
import { FeLandingCubesComponent } from './fe-landing-cubes/fe-landing-cubes.component';
import { FeLandingSocialsCubesComponent } from './fe-landing-socials-cubes/fe-landing-socials-cubes.component';
import { FeLandingProgressStepsComponent } from './fe-landing-progress-steps/fe-landing-progress-steps.component';
import { FeAboutLandingComponent } from './fe-about-landing/fe-about-landing.component';
import { RouterModule } from '@angular/router';
import { FeLandingMotiveComponent } from './fe-landing-motive/fe-landing-motive.component';
import { FeLandingPromoCubesComponent } from './fe-landing-promo-cubes/fe-landing-promo-cubes.component';
import { FeShirtPhotoGridComponent } from './fe-shirt-photo-grid/fe-shirt-photo-grid.component';
import { FeSweaterPhotoGridComponent } from './fe-sweater-photo-grid/fe-sweater-photo-grid.component';
import { FeLandingGroupCubesComponent } from './fe-landing-group-cubes/fe-landing-group-cubes.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FeLandingReviewsComponent } from './fe-landing-reviews/fe-landing-reviews.component';
import { LandingCubeReviewComponent } from './fe-landing-reviews/landing-cube-review/landing-cube-review.component';



@NgModule({
  declarations: [FeLandingBannerComponent, FeLandingCubesComponent, FeLandingSocialsCubesComponent, FeLandingProgressStepsComponent, FeAboutLandingComponent, FeLandingMotiveComponent, FeLandingPromoCubesComponent, FeShirtPhotoGridComponent, FeSweaterPhotoGridComponent, FeLandingGroupCubesComponent, FeLandingReviewsComponent, LandingCubeReviewComponent],
  imports: [
    CommonModule,
    UiAtomsModule,
    UiMoleculesModule,
    UiOrganismsModule,
    RouterModule,
    IvyCarouselModule
  ],
  exports: [
    FeLandingBannerComponent,
    FeLandingCubesComponent,
    FeLandingSocialsCubesComponent,
    FeLandingProgressStepsComponent,
    FeAboutLandingComponent,
    FeLandingMotiveComponent,
    FeLandingPromoCubesComponent,
    FeShirtPhotoGridComponent,
    FeSweaterPhotoGridComponent,
    FeLandingGroupCubesComponent,
    FeLandingReviewsComponent
  ]
})
export class TemplatesModule { }
