import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-fe-input-m',
  templateUrl: './fe-input-m.component.html',
  styleUrls: ['./fe-input-m.component.scss']
})
export class FeInputMComponent implements OnInit {
  @Input() type : string;
  @Input() placeHolder : string;
  @Input() set control(value: FormControl) {
    if (this.formControl !== value) {
      this.formControl = value;
    }
  }
  @Input() maxLength : number;
  formControl: FormControl;
  
  constructor() { }

  ngOnInit(): void {
  }

}
