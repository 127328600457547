import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fe-landing-banner',
  templateUrl: './fe-landing-banner.component.html',
  styleUrls: ['./fe-landing-banner.component.scss']
})
export class FeLandingBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
