<div class="container-fluid about-landing-container">
    <div class="row no-gutters">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="about-header">
                <h1>Even kennis maken?</h1>
            </div>
            <div class="row no-gutters">
                <div class=" about-founders">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 bio lars" [routerLink]="['bio', 'lars']"> 
                       <h2>Lars</h2>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 bio rens" [routerLink]="['bio', 'rens']">
                        <h2>Rens</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 motto">
            <div class="about-header">
                <h1>#Genietnooitmetmate!</h1>
            </div>
         
        </div>
    </div>
</div>