import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { PopupComponent } from './contents/content/landing/popup/popup.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'FE';
  modalRef?: BsModalRef | null;
  constructor(private bsModal : BsModalService){}

  ngOnInit(): void {
    let skipModal = JSON.parse(localStorage.getItem('skipModal'));
    this.openModal(skipModal)
  }


  openModal(skipModal:boolean) {
    if(!skipModal){
      localStorage.setItem('skipModal', JSON.stringify(true));
    }
  }
}
