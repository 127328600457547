<div class="email-form">
    <app-fe-label
        [value]="'Meld je aan'">
    </app-fe-label>
    <app-fe-input-s 
        [placeHolder]="'E-mail Adres'"
        >
    </app-fe-input-s>
    <app-fe-button 
        [textValue]="'Aanmelden'">
    </app-fe-button>
</div>