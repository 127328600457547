<div class="container-fluid socials-cubes-container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row no-gutters">
        <div class="socials-header col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h1>For Effect Socials</h1>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-4 col-xl-4">  
            <div class="cube">
                <a href="https://www.instagram.com/foreffect_/">
                    <div class="img-overlay "></div> 
                    <img class="img-fluid" src="assets/images/app-wide/socials/fe-1-socials.jpeg">
                </a>
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div class="cube">
                <a href="https://www.instagram.com/foreffect_/">
                    <div class="img-overlay "></div> 
                    <img class="img-fluid" src="assets/images/app-wide/socials/fe-2-socials.jpeg">
                </a>
            </div>
        </div>
        <div class="socials-cubes-cube col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div class="cube">
                <a href="https://www.instagram.com/foreffect_/">
                    <div class="img-overlay "><a href="https://www.instagram.com/foreffect_/"></a></div> 
                    <img class="img-fluid" src="assets/images/app-wide/socials/fe-1-socials.jpeg">
                </a>    
            </div>
        </div>
    </div>
</div>
 