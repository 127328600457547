import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductRatingComponent),
      multi: true
    }
  ]
})
export class ProductRatingComponent implements ControlValueAccessor {
  ratings = [1,2,3,4,5,6,7,8,9,10];
  productRating : number;

  private onChange: (value: number) => void;
  private onTouched: () => void;

  constructor() { }

  public writeValue(value: number): void {
    this.productRating = value;
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }

  public onSelection(rating : number): void {
    // enforce the phone number format

    // notify the form control that the value has changed
    this.writeValue(rating);
    this.onChange(rating);
  }

  public onBlur(): void {
    this.onTouched();
  }

}
